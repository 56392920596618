import { AuthState, useIdentify } from '@hungryroot/customer';
import { useDefaultValue } from '@hungryroot/environment';
import { Footer as HungryrootFooter } from '@hungryroot/footer';
import { islandRoot } from '../IslandRoot/IslandRoot';

export const FooterCustomerLinksIsland = islandRoot(FooterCustomerLinks);

function FooterCustomerLinks() {
  const auth = useIdentify();
  const authValue = useDefaultValue(auth, {
    authState: AuthState.isLoading,
  });

  return <HungryrootFooter.CustomerLinks customerAuthState={authValue} />;
}
